import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Message, Utils } from 'src/app/shared/utils/utils';
import { AuthService, IUser } from 'src/app/shared/auth/auth.service';
import { Md5 } from 'ts-md5';

declare var $;

@Component({
    selector: 'app-topnav',
    templateUrl: './topnav.component.html',
    styleUrls: []
})
export class TopnavComponent implements OnInit {
    version: string = "";
    empresa: string = "";

    loading: boolean = false;
    
    // model_chamado = {
    //     departamento: '',
    //     nome: '',
    //     email: '',
    //     telefone: '',
    //     assunto: '',
    //     mensagem: '',
    //     username: '',
    //     db: '',
    //     prioridade: 0
    // };

    // abreFormularioChamado: boolean = false;

    // opcoesDepartamento = [
    //     { valor: '2d6e161238c2299e59e62417edbf9667', nome: 'Comercial' },
    //     { valor: 'b2621afb9c0090d67a3f62668d7c7e67', nome: 'Desenvolvimento' },
    //     { valor: 'a4e5c7baaf7cbf37346dfc0afcfc5a3e', nome: 'RH' }
    // ];

    user: IUser;
    iconTheme = '';
    labelChangeTheme = '';

    url_teste: string = 'http://localhost:5001';
    url_mainapi: string = 'https://api.cpsadmin.com.br';

    constructor(private http: HttpClient, private auth: AuthService) { }

    ngOnInit() {
        this.user = this.auth.user;
        this.version = sessionStorage.getItem("version") != null ? sessionStorage.getItem("version") : localStorage.getItem("version");
        this.empresa = JSON.parse(sessionStorage?.getItem("app_user")) != null ? JSON.parse(sessionStorage?.getItem("app_user")).empresaNome.split(' ')[0] : "";
        
        if(sessionStorage?.getItem("app_user") != null && JSON.parse(sessionStorage?.getItem("app_user")).tema_sistema == 1){
            this.setTheme('dark');
            // console.log("aqui");
        }
        else
        {
            this.setTheme('light');
        }
    }

    logout() {
        this.auth.logout();
        this.auth.redirectToLogin();
    }

    alternateTheme(){
        if (localStorage.getItem('theme') == 'light') {
            this.salvaTema('dark');
        } else {
            this.salvaTema('light');
        }
    }

    async salvaTema(theme){
        // console.log("chamou");
        var raw = {
            "username": this.user.username,
            "k": Md5.hashAsciiStr("hash do tema: " + this.user.username + this.version).toUpperCase(),
            "db": this.version,
            "tema_sistema": theme == 'dark' ? 1 : 0
        };

        await this.http.post<any>('AtualizaTema', raw).subscribe(n => {
            if (!n.success) {
                Message.info(n.message);
            }
        }, () => {
            Message.error('Não foi possível conectar');
        });

        var app_user =  JSON.parse(sessionStorage?.getItem("app_user"));
        app_user.tema_sistema = (theme == 'dark') ? 1 : 0;
        // console.log(JSON.stringify(app_user));
        sessionStorage.setItem('app_user', JSON.stringify(app_user));

        this.setTheme(theme);
    }

    setTheme(theme) {
        if (theme == 'light') {
            // document.getElementById('csstheme').setAttribute('href', 'assets/css/theme-dark.min.css');
            document.getElementById('csstheme').setAttribute('href', 'assets/css/theme.min.css');
            document.getElementById('cssthemecustom').setAttribute('href', 'assets/css/theme.css');
            this.iconTheme = 'moon';
            this.labelChangeTheme = 'Modo Escuro';
        } else {
            // document.getElementById('csstheme').setAttribute('href', 'assets/css/theme.min.css');
            document.getElementById('csstheme').setAttribute('href', 'assets/css/theme-dark.min.css');
            document.getElementById('cssthemecustom').setAttribute('href', 'assets/css/theme-dark.css');
            this.iconTheme = 'sun';
            this.labelChangeTheme = 'Modo Claro';
        }
        localStorage.setItem('theme', theme);
    }

    // abrirChamado(){
    //     $('.modalChamado').modal('show');
    // }

    // alteraDepartamento(event){
    //     // console.log(event.target.selectedIndex);
    //     if(event.target.selectedIndex > 0)
    //     {
    //         this.abreFormularioChamado = true;
    //     }
    //     else
    //     {
    //         this.abreFormularioChamado = false;
    //         this.cleanModelChamado();
    //     }
    // }

    // async EnviarChamado(){
    //     this.loading = true;
    //     try {
    //         this.model_chamado.username = this.user.username;
    //         this.model_chamado.prioridade = 4;

    //         var raw1 = {
    //             "k": Md5.hashAsciiStr("hash do ticket: " + this.model_chamado.username + (new Date()).getDate()).toUpperCase(),
    //             "cliente": "CpsAdmin",
    //             "username": this.model_chamado.username,
    //             "departamento": this.model_chamado.departamento,
    //             "assunto": this.model_chamado.assunto,
    //             "mensagem": this.model_chamado.mensagem,
    //             "prioridade": this.model_chamado.prioridade,
    //         };

    //         await this.http.post<any>('abrirticket', raw1).subscribe(n => {
    //             if (n != null && n.success) {
    //                 // console.log(n);
    //                 Message.success("Ticket criado!");
    //                 this.abreFormularioChamado = false;
    //                 this.cleanModelChamado();
    //                 $('.modalChamado').modal('hide');
    //             } else {
    //                 console.log(n);
    //                 Message.info(n.errors);
    //             }

    //             this.loading = false;
    //         }, (e) => {
    //             Message.error('Não foi possível Enviar Chamado. e: ' + e.toString());
    //         });
    //     } catch (error) {
    //         Message.error("Problema ao enviar chamado. Entre em contato com o TI. 'erro': " + error);
    //     }
    // }

    // cleanModelChamado(){
    //     this.model_chamado = {
    //         departamento: '',
    //         nome: '',
    //         email: '',
    //         telefone: '',
    //         assunto: '',
    //         mensagem: '',
    //         username: '',
    //         db: '',
    //         prioridade: 0
    //     };
    // }
}
